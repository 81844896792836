// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../../libs/ID.res.js";
import * as Hooks from "../../../../../../libs/Hooks.res.js";
import * as Label from "../../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../../bindings/Promise.res.js";
import * as Checkbox from "../../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as IconReset from "../../../../../../styleguide/icons/IconReset.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SearchField from "../../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as Api_Provider from "../../../../../../api/providers/Api_Provider.res.js";
import * as SentryLogger from "../../../../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderBrochuresDashboardProviderFilterScss from "./ProviderBrochuresDashboardProviderFilter.scss";

var css = ProviderBrochuresDashboardProviderFilterScss;

var initialState_providers = [];

var initialState_providerIds = [];

var initialState = {
  providerSearch: "",
  status: "FetchingProviders",
  providers: initialState_providers,
  providerIds: initialState_providerIds
};

function ProviderBrochuresDashboardProviderFilter(props) {
  var reset = props.reset;
  var updateProviderIds = props.updateProviderIds;
  var searchProviders = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformProviderSearch",
                _0: param.state.providerSearch
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchProviders" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              $$Promise.wait(Api_Provider.fetchProvidersFilter(true, state.providerSearch), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedProvidersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ProviderBrochuresDashboardProviderFilter",
                                            subModulePath: /* [] */0,
                                            value: "make",
                                            fullPath: "ProviderBrochuresDashboardProviderFilter.make"
                                          }, "FailProvidersFetch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailProvidersFetch");
                                    }));
                            })
                        };
              case "FailProvidersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            providerSearch: state.providerSearch,
                            status: "FailedProvidersFetch",
                            providers: state.providers,
                            providerIds: state.providerIds
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providerSearch: "",
                            status: state.status,
                            providers: state.providers,
                            providerIds: []
                          },
                          _1: (function (param) {
                              param.dispatch("FetchProviders");
                              reset();
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedProvidersFetch" :
                  var res = action._0;
                  return {
                          TAG: "Update",
                          _0: {
                            providerSearch: state.providerSearch,
                            status: {
                              TAG: "Ready",
                              _0: res
                            },
                            providers: res.providers,
                            providerIds: state.providerIds
                          }
                        };
              case "UpdateProviderSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providerSearch: action._0,
                            status: state.status,
                            providers: state.providers,
                            providerIds: state.providerIds
                          },
                          _1: searchProviders
                        };
              case "PerformProviderSearch" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingProviders") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.providerSearch) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              providerSearch: state.providerSearch,
                              status: "FetchingProviders",
                              providers: state.providers,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchProviders");
                              })
                          };
                  }
              case "UpdateProviderIds" :
                  var ids = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            providerSearch: state.providerSearch,
                            status: state.status,
                            providers: state.providers,
                            providerIds: ids
                          },
                          _1: updateProviderIds(ids)
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchProviders");
        }), []);
  var match$1 = state.status;
  var exit = 0;
  if (typeof match$1 !== "object") {
    if (match$1 !== "FetchingProviders") {
      return null;
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var match$2 = state.providerSearch;
    var match$3 = state.providerIds;
    var tmp;
    var exit$1 = 0;
    if (match$2 === "" && match$3.length === 0) {
      tmp = null;
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      tmp = JsxRuntime.jsx("a", {
            children: JsxRuntime.jsx(IconReset.make, {
                  title: "Reset Filters",
                  size: "LG",
                  color: "LighterGray"
                }),
            className: css.reset,
            href: "#",
            onClick: (function (param) {
                dispatch("Reset");
              })
          });
    }
    var x = state.providerIds.length;
    return JsxRuntime.jsxs("div", {
                children: [
                  tmp,
                  JsxRuntime.jsxs(Dropdown.make, {
                        className: css.providersDropdown,
                        children: [
                          JsxRuntime.jsx(Dropdown.Trigger.make, {
                                className: css.dropdownTrigger,
                                iconSize: "XS",
                                iconColor: "Gray",
                                children: x !== 0 ? (
                                    x !== 1 ? String(x) + " Providers" : "1 Provider"
                                  ) : "All Providers"
                              }),
                          JsxRuntime.jsxs(Dropdown.Body.make, {
                                position: {
                                  TAG: "Below",
                                  _0: "RightEdge"
                                },
                                className: css.dropdownBody,
                                children: [
                                  JsxRuntime.jsx(SearchField.make, {
                                        id: "providers-search",
                                        value: state.providerSearch,
                                        placeholder: "Provider Name",
                                        inputClassName: css.providersSearch,
                                        onChange: (function ($$event) {
                                            dispatch({
                                                  TAG: "UpdateProviderSearchInput",
                                                  _0: $$event.target.value
                                                });
                                          }),
                                        showSearchIcon: false
                                      }),
                                  Belt_Array.map(state.providers, (function (provider) {
                                          var id = "location-search-filter--provider-" + ID.toString(provider.id);
                                          return JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx(Checkbox.make, {
                                                              id: id,
                                                              size: "SM",
                                                              checked: (function (__x) {
                                                                    return Js_array.findIndex((function (value) {
                                                                                  return ID.toString(value) === ID.toString(provider.id);
                                                                                }), __x);
                                                                  })(state.providerIds) > -1,
                                                              onChange: (function ($$event) {
                                                                  var checked = $$event.target.checked;
                                                                  var providerIds = checked ? Belt_Array.concat(state.providerIds, [provider.id]) : Belt_Array.keep(state.providerIds, (function (value) {
                                                                            return ID.toString(value) !== ID.toString(provider.id);
                                                                          }));
                                                                  dispatch({
                                                                        TAG: "UpdateProviderIds",
                                                                        _0: providerIds
                                                                      });
                                                                })
                                                            }),
                                                        JsxRuntime.jsx(Label.make, {
                                                              forId: id,
                                                              className: css.label,
                                                              children: provider.name
                                                            })
                                                      ],
                                                      className: css.dropdownBodyRow
                                                    }, id);
                                        }))
                                ]
                              })
                        ]
                      })
                ],
                className: css.filterContainer
              });
  }
  
}

var make = ProviderBrochuresDashboardProviderFilter;

export {
  css ,
  initialState ,
  make ,
}
/* css Not a pure module */
